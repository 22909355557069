button {
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
}

.tabs {
    background:
    linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      to right,
      #FFB534,
      #FFB534,
      #7626ff
  );
  background-size: 100% 3px, 0 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.tabs {
    margin: 0px 20px;
    color: #365486;
}
.active-tabs {
    background-size: 0 3px, 100% 3px;
    transform: scale(1.3);
    transition: all 0.3s ease-in-out;
}


.tab-container {
    width: 95%;
    padding: 20px 50px;
    border-radius: 20px;
    align-items: center;
    margin: auto;
}

.tab-box {
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;
    border-bottom: 2px solid rgba(229,229,229);
}

.tab-box .tabs {
    font-size: 18px;
    font-weight: 600;
    color: #919191;
    background: none;
    border: none;
    padding: 0px 18px 18px 18px;
}

.content-tabs {
    padding: 20px;
    color: rgb(0, 0, 0);
}

.content-tabs .content {
    display: none;
}

.content-tabs .active-content{
    display: block;
}

.our-icons {
    margin-right: 3px;
    font-size: 22px;
}

.content-tabs p {
    text-align: justify;
    padding: 0 120px;
}

@media only screen and (max-width: 992px) {
    .content-tabs p {
        padding: 0px 0px;
    }
}

@media only screen and (max-width: 480px) {
    .tab-container {
        padding: 30px 0px;
    }
}