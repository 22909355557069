.banner-bg {
    padding-bottom: 0px;
    width: 100%;
    height: 100%;
}

.banner-content {
    padding-top: 100px;
}

.banner-content h1 {
    color: #ffffff;
    font-weight: 400;
    font-size: 40px;
    font-family: 'Arvo', sans-serif;
    padding-top: 20px;
    text-shadow: 1px 2px 2px #000;
}

.banner-content p {
    color: #ffffff;
    padding: 10px;
    margin: 0px 220px;
    font-weight: 500;
    font-size: 20px;
    text-shadow: 1px 2px 3px #000;
    line-height: 35px;
    background-color: rgba(0, 0, 0, 0.324);
    border-radius: 5px;
}


@media only screen and (max-width: 992px) {
    
    .banner-content p {
        margin: 0px 100px;
    }
}

@media only screen and (max-width: 480px) {
    .banner-content p {
        margin: 0px 10px;
    }
}


.con-icon {
    font-size: 30px;
    margin-right: 5px;
}

.banner-contact {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.banner-contact a {
    text-decoration: none;
}

.banner-contact-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    background-color: white;
    padding: 0px 15px;
    border-radius: 5px;
    color: #7626ff;
}

.banner-contact-btn:hover {
    background-color: #7626ff;
    color: white;
    transition: all 0.2s ease;
}

.banner-contact p {
    margin-top: 18px;
}

.custom-bg {
    filter: brightness(60%);
}

.custom-bg img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}






