.images {
    width: 90%;
    height: auto;
    object-fit: cover;
    padding-top: 25px;
    padding-bottom: 5px;
    cursor: pointer;
}

#gallery {
    margin-top: 100px;
    overflow: hidden;
}

.gallery-gap {
    margin-bottom: 30px;
}
