@import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&family=Josefin+Sans:ital,wght@0,400;0,600;1,400;1,600&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300&family=Poppins:ital,wght@0,300;0,400;0,600;0,700;1,400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100&family=Teko:wght@400;500;600&display=swap');


.App {
  text-align: center;
}

body {
  font-family: 'Poppins';
  scroll-behavior: smooth;
}

