#about-container {
    padding-top: 150px;
    font-family: 'Poppins';
}

.about-icons {
    margin-right: 3px;
    font-size: 30px;
}

.about-title {
    color: #365486;
    font-weight: 600;
    font-size: 25px;
}

#about-container p {
    text-align: justify;
    padding: 0 280px 100px 280px;
}

@media only screen and (max-width: 1000px) {
    #about-container p {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 480px) {
    .tab-container {
        padding: 30px 0px;
    }
}