@media only screen and (min-width: 992px) {
    nav .all-links {
        margin-right: 100px;
    }
}

@media only screen and (max-width: 992px) {
    nav .all-links {
        margin-right: -26px;
    }
}

.logo {
    width: 300px;
    margin-top: -25px;
    margin-bottom: -10px;
    margin-left: -10px;
    margin-right: -20px;
    margin-bottom: -50px;
}

nav .links {
    font-weight: 500;
    font-family: 'Poppins' ;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: #7626ff;
    margin-right: 30px;
}

 nav .links:hover {
    color: white;
    background-color: #7626ff;
    border-radius: 5px;
    transition: all 0.2s ease;
}

#basic-nav-dropdown {
    color: #7626ff;
}

#basic-nav-dropdown:hover {
    color: white;
    background-color: #7626ff;
    border-radius: 5px;
}

.navbar-css {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navbar-css h1 {
    color: rgb(255, 255, 255);
    font-size: 30px;
}

.linkss {
    position: relative;
    padding-bottom: 2px;
}

@media only screen and (max-width: 480px) {
    .logo {
        width: 284px
    }
}