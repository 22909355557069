.footer-css {
    background-color: #000000;
    margin-bottom: -30px;
    padding-top: 20px;
    color: white;
    font-weight: 300;
}

footer {
    padding-bottom: 10px;
}

.main-footer {
    font-size: 8px;
    padding-bottom: 5px;
}