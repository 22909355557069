#about-content {
    margin-top: 100px;
    margin-left: 100px;
    margin-right: 200px;
    font-family: 'Poppins';
    overflow-x: hidden;
    justify-content: center;
}

#about-content h1 {
    text-decoration: underline;
    margin-top: 20px;
    margin-bottom: 60px;
    color: #365486;
    display: block;
}

.content h2 {
    margin-top: 10px;
    color: #365486;
}

.imagess {
    width: 300px;
    height: 200px;
}

#about-content img {
    border-radius: 5px;
}

.content p {
    text-align: justify;
}

.gap {
    margin-bottom: 80px;
}

.title {
    font-size: 45px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 992px) {
    .gaps {
        margin-bottom: 50px;
    }

    #about-content {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.gapss {
    margin-bottom: 50px;
} 