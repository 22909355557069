#contact {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.contact-details {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 26px;
}

.details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    padding: 7px 15px;
    border-radius: 5px;
    color: #ffffff;
    flex-wrap: wrap;
}

.details:hover {
    color: #7626ff;
    background-color: #ffffff;
}

.details p {
    margin-bottom: -4px;
}

.detailss p {
    margin-bottom: -5px;
}

.detailss {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 15px;
    border-radius: 5px;
    color: #ffffff;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.detail-icon {
    font-size: 30px;
    margin-right: 5px;
}


.contact-details a {
    text-decoration: none;
}

.custom-bg-two {
    filter: brightness(30%);
}

.custom-bg-two img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}